<template>
  <div class="tab">
    <overlay-loader :loading="isLoading" />
    <hcc-input
      type="text"
      size="lg"
      icon=""
      placeholder="Search..."
      class="tab__search-input"
      name="Search"
      v-model="searchData"
    >
      <template #icon><magnify-icon /></template>
    </hcc-input>
    <div class="location__container">
      <media-card
        v-for="ln in locationsLists"
        :media="ln"
        mediaType="location"
        :key="ln.id"
        :isEdit="true"
        :deleteMedia="deleteLocation"
        :openModal="openMaps"
        :editMedia="edit"
      >
        <div class="location__item" @click="openMaps(ln.location)">
          <map-location-static
            class="location__thumbnail"
            :lat="ln.location.lat"
            :lng="ln.location.lng"
          />
        </div>
      </media-card>
      <hcc-card class="location__add" @click="addLocation">
        <hcc-button-icon>
          <plus-circle-icon />
          <p>{{ $t("campaigns.data-bank.location.add") }}</p>
        </hcc-button-icon>
      </hcc-card>
      <modal height="auto" width="1000" name="maps-modal">
        <div>
          <gmap-map
            :center="locationModal"
            :zoom="defaultZoom"
            class="map-container"
          >
            <gmap-marker
              :clickable="true"
              :draggable="false"
              :position="locationModal"
            />
          </gmap-map>
        </div>
      </modal>
      <edit-location
        :location="location"
        :order="order"
        :edit="isEdit"
        class="location__modal"
        @closed="isEdit = null"
      />
      <hcc-confirmation />
    </div>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import { UPDATE_DATA_BANK } from '@/eventTypes';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';

export default {
  props: {
    databank: {
      required: true,
    },
  },
  components: {
    OverlayLoader,
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    MediaCard: () => import('@/components/settings/dataBank/MediaComponents/MediaCard.vue'),
    MapLocationStatic: () => import('@/components/MapLocationStatic.vue'),
    EditLocation: () => import('@/components/settings/dataBank/EditLocation.vue'),
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
  },
  data() {
    return {
      locationList: [],
      location: {},
      locationModal: {},
      defaultCoordinates: [{
        lat: 20.677467,
        lng: -103.347939,
      }],
      defaultZoom: 16,
      query: '',
      currentFile: {
        textId: 0,
        index: 0,
      },
      searchData: '',
      isEdit: null,
    };
  },
  watch: {
    'databank.location': function setLocation(newLocation) {
      this.locationList = newLocation || [];
      this.location = {};
    },
  },
  mounted() {
    this.locationList = this.databank.location || [];
  },
  computed: {
    locationsLists() {
      return this.locationList.filter((cord) => {
        if (cord && cord.location) {
          const name = cord.location.name ? cord.location.name.toLowerCase() : '';
          const value = this.searchData.toLowerCase();
          return name.includes(value);
        }
        return false;
      }).map(({ location, ...cord }) => (
        { ...cord, location: { ...location, name: location.name || '' } }
      ));
    },
    order() {
      return this.locationList.length;
    },
    isLoading() {
      return this.databank.location === null;
    },
  },
  methods: {
    openMaps(props) {
      this.locationModal = props;
      this.$modal.show('maps-modal');
    },
    edit(id) {
      this.location = this.getLocation(id);
      this.isEdit = true;
      this.$modal.show('edit-location');
    },
    deleteLocation(props) {
      this.$modal.show('confirmation', {
        title: this.$t('common.delete'),
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: () => {
          this.update({ id: props }, true);
        },
      });
    },
    update(data, remove) {
      EventBus.$emit(UPDATE_DATA_BANK, { data, remove, type: 'location' });
    },
    getLocation(id) {
      return this.locationList.find(ln => ln.id === id);
    },
    addLocation() {
      this.location = {};
      this.isEdit = false;
      this.$modal.show('edit-location');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/dataBank/_location_tab.scss";
</style>
